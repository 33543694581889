/*
 * Copyright contributors to the Hyperledger Fabric Operations Console project
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
*/
import PropTypes from 'prop-types';
import React from 'react';
import SvgContainer from './SvgContainer';

const Settings = ({ extendClass, height, title, width }) => {
	return (
		<SvgContainer width={width || '28px'}
			height={height || '28px'}
			extendClass={extendClass}
			viewBox="0 0 28 28"
			role="img"
		>
			<title>{title}</title>
			<g>
				<g stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<g className="ibp-fill-color"
						fillRule="nonzero"
					>
						<path
							d="M24.962,14.757 C24.979,14.505 25,14.255 25,14 C25,13.742 24.99,13.487 24.972,13.234 L26.892,11.546
							C27.6083708,10.9170159 27.7812254,9.86984354 27.305,9.044 L24.945,4.956 C24.4687298,4.12984588 23.4752223,3.75513487
							22.572,4.061 L20.138,4.885 C19.7185747,4.60223634 19.2802081,4.34863859 18.826,4.126 L18.322,1.608 C18.1350653,0.672750025
							17.3137489,-0.000377769244 16.36,8.8817842e-16 L11.64,8.8817842e-16 C10.686627,9.90522536e-05 9.8658611,0.673118746 9.679,1.608
							L9.175,4.126 C8.71573391,4.34628913 8.27233238,4.5982294 7.848,4.88 L5.428,4.061 C4.52507369,3.75567979 3.53217797,4.13031793
							3.056,4.956 L0.696,9.044 C0.219536242,9.86961475 0.391966468,10.9167517 1.108,11.546 L3.038,13.243 C3.022,13.495 3,13.745 3,14
							C3,14.258 3.01,14.513 3.028,14.766 L1.108,16.454 C0.391629187,17.0829841 0.218774603,18.1301565 0.695,18.956 L3.055,23.044
							C3.53127022,23.8701541 4.52477773,24.2448651 5.428,23.939 L7.862,23.115 C8.28141865,23.3977744 8.71978599,23.6513725 9.174,23.874
							L9.678,26.392 C9.86493472,27.32725 10.6862511,28.0003778 11.64,28 L16.36,28 C17.313373,27.9999009 18.1341389,27.3268813 18.321,26.392
							L18.825,23.874 C19.2839298,23.6536812 19.7269967,23.4017412 20.151,23.12 L22.571,23.939 C23.4742223,24.2448651 24.4677298,23.8701541
							24.944,23.044 L27.304,18.956 C27.7804638,18.1303852 27.6080335,17.0832483 26.892,16.454 L24.962,14.757 Z M23.212,22.044 L19.785,20.884
							C18.9781987,21.5600164 18.0593466,22.0896677 17.07,22.449 L16.36,26 L11.64,26 L10.93,22.449 C9.94796524,22.0847534 9.03622841,21.5536117
							8.235,20.879 L4.788,22.044 L2.428,17.956 L5.154,15.561 C4.96754479,14.5268754 4.96517507,13.4679487 5.147,12.433 L2.427,10.044 L4.79,
							5.956L8.216,7.116 C9.02238919,6.4396783 9.94090886,5.909685 10.93,5.55 L11.64,2 L16.36,2 L17.07,5.551 C18.0519918,5.91533753 18.9637137,
							6.4464705 19.765,7.121 L23.212,5.956 L25.572,10.044 L22.846,12.439 C23.0324762,13.4731227 23.0348459,14.5320531 22.853,15.567
							L25.572,17.956L23.212,22.044 Z"
						/>
						<path
							d="M14,20 C10.6862915,20 8,17.3137085 8,14 C8,10.6862915 10.6862915,8 14,8 C17.3137085,8 20,10.6862915 20,14
							C20.0173082,15.5965421 19.3907267,17.1327426 18.2617346,18.2617346 C17.1327426,19.3907267 15.5965421,20.0173082
							14,20 Z M14,10 C12.9318669,9.97568963 11.9002652,10.3893057 11.1447855,11.1447855 C10.3893057,11.9002652 9.97568963,12.9318669
							10,14 C9.97568963,15.0681331 10.3893057,16.0997348 11.1447855,16.8552145 C11.9002652,17.6106943 12.9318669,18.0243104 14,18 C15.0681331,
							18.0243104 16.0997348,17.6106943 16.8552145,16.8552145 C17.6106943,16.0997348 18.0243104,15.0681331 18,14 C18.0243104,12.9318669
							17.6106943,11.9002652 16.8552145,11.1447855 C16.0997348,10.3893057 15.0681331,9.97568963 14,10 Z"
						/>
					</g>
				</g>
			</g>
		</SvgContainer>
	);
};

Settings.propTypes = {
	extendClass: PropTypes.string,
	height: PropTypes.string,
	title: PropTypes.string,
	width: PropTypes.string,
};

export default Settings;
